import { AddressDelivery } from '../../redux/addressManagement/types';
import { DeliveryDataForText } from './getDeliveryText';

export const getDeliveryDataFromAddress = (
  delivery: AddressDelivery,
): DeliveryDataForText => {
  const deliveryType = delivery?.type;

  if (deliveryType === 'plan') {
    const { city, street, building, room } = delivery.address.plan;

    return {
      type: 'plan',
      city,
      street,
      building,
      room,
    };
  } else if (deliveryType === 'pickup') {
    const { pickup_zone, store } = delivery.address.pickup;

    return {
      type: deliveryType,
      pickupZone: pickup_zone,
      city: store.address.city,
      street: store.address.street,
      building: store.address.building,
    };
  } else if (deliveryType === 'nova_poshta') {
    const { settlement, warehouse } = delivery.address.nova_poshta;

    return {
      type: deliveryType,
      localityLabel: settlement.name,
      departmentNumber: warehouse.number.toString(),
      novaPoshtaType: warehouse.type,
    };
  } else if (deliveryType === 'nova_poshta_fresh') {
    const { settlement, warehouse } = delivery.address.nova_poshta_fresh;

    return {
      type: deliveryType,
      localityLabel: settlement.name,
      departmentNumber: warehouse.number.toString(),
      novaPoshtaType: warehouse.type,
    };
  } else if (deliveryType === 'nova_poshta_address') {
    const { building, settlement, street } = delivery.address.nova_poshta_address.address;

    return {
      type: deliveryType,
      localityLabel: settlement.name,
      street: street.name,
      building,
    };
  }
};
